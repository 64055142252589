/* eslint-disable react/prop-types */
import React, { useState, useContext, useEffect } from 'react';
import { withRouter } from 'react-router';
import { Table, Button, Space, Typography, message, Tag } from 'antd';
import { StyleSheet, css } from 'aphrodite';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { colors } from '@zipdrug/ui';
import { daysBetween } from 'dashboard/shared/utils';
import withRoles from '../../../../../hoc/withRoles';
import withSelectPatient from '../../../../../hoc/withSelectPatient';
import { MembersListContext, ActionTypes } from '../../../../../contexts/MemberListProvider';
import { getStatusFiltersForMembers, statusTypes } from '../../constants/memberStatus';
import GET_PHARMACY_MEMBERS from '../../graphql/getPharmacyMembers';
import PATIENT_CLICKED from '../../graphql/updateMemberClicked';
import filterSearchBox from '../Filters/Search/Search';
import generateVariables from '../../utils/GenerateVariables.utils';
import languages from '../../constants/languages';
import outReachType from '../../constants/outreachType';

const { Link, Text } = Typography;

const sx = StyleSheet.create({
  membersTableOptionContainer: {
    marginBottom: '20px',
  },
  membersTableOptionsButtonMargin: {
    marginRight: '16px',
  },
  membersTextContainer: {
    display: 'flex !important',
    justifyContent: 'space-between',
  },
  membersText: {
    color: colors.gray52,
  },
  newMemberIcon: {
    width: '51px',
    height: '22px',
    color: '#231E33',
    border: '1px solid #D9D9D9',
    backgroundColor: '#F5F5F5',
    borderRadius: '4px',
    fontSize: '12px',
    float: 'right',
    textAlign: 'center',
  },
  careGapIcon: {
    width: '73px',
    height: '23px',
    color: '#F5222D',
    border: '1px solid #FFA39E',
    backgroundColor: '#FFF1F0',
    borderRadius: '4px',
    fontSize: '12px',
    float: 'right',
    textAlign: 'center',
  },
  boldText: {
    fontWeight: 'bold',
  },
});

const MembersTable = ({ onSelectPatient, history, user, isPharmacistTeam, isSystemAdminOrAsc }) => {
  const [data, setData] = useState([]);
  const { state, dispatch } = useContext(MembersListContext);
  const { tableActions, defaultTableActions } = state;
  const [totalMembers, setTotalMembers] = useState(0);

  let pharmacyId;
  if (user && isPharmacistTeam) {
    const role = user?.roles?.find(r => r.pharmacy_id !== null);
    pharmacyId = role?.pharmacy_id;
    if (!pharmacyId) {
      message.error('No pharmacy assigned to user Roles');
    }
  }

  const [updatePatientClicked] = useMutation(PATIENT_CLICKED, {
    onError: () => {
      message.error('An error occured on updating patient clicked');
    },
  });

  const onMemberClick = patientId => {
    onSelectPatient(patientId);
    updatePatientClicked({
      variables: {
        patientId,
      },
    });
    history.push('/members');
  };

  const [
    getMembersList,
    { loading: isFetchMembersList, data: membersData, refetch: refetchMemberList },
  ] = useLazyQuery(GET_PHARMACY_MEMBERS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    onError: error => {
      message.error(`Error fetching Members List: ${error.message}`);
    },
    onCompleted: ({ getEnrolledMembersForPharmacy }) => {
      setTotalMembers(getEnrolledMembersForPharmacy?.total);
      setData(getEnrolledMembersForPharmacy?.data);
    },
  });

  const generateColumns = () => {
    const headers = [
      {
        title: 'Enrolled Date',
        dataIndex: 'enrolled_date',
        key: 'enrolled_date',
        width: '8%',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sortOrder:
          tableActions?.sorter?.columnKey === 'enrolled_date' && tableActions?.sorter?.order,
        render: (text, record) => {
          const isNewMember = record.action_items.includes('New members');
          const isAlreadyClicked = record.click_redirect;
          return isNewMember && !isAlreadyClicked && isPharmacistTeam ? <b>{text}</b> : <>{text}</>;
        },
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: '18%',
        ...filterSearchBox('name', 'Name', tableActions?.filters?.name),
        filteredValue: tableActions?.filters?.name || null,
        render: (text, record) => {
          const isNewMember = record.action_items.includes('New members');
          const isAlreadyClicked = record.click_redirect;
          const isUnresolvedCareGap = record.action_items.includes('Unresolved Care Gaps');
          return (
            <>
              <Link underline onClick={() => onMemberClick(record.id)}>
                {isNewMember && !isAlreadyClicked && isPharmacistTeam ? <b>{text}</b> : <>{text}</>}
              </Link>
              {isUnresolvedCareGap && isPharmacistTeam && (
                <Tag className={css(sx.careGapIcon)}>Care Gap</Tag>
              )}
              {Math.abs(daysBetween(moment().format('MM-DD-YYYY'), record.enrolled_date)) <= 7 &&
                isPharmacistTeam && <Tag className={css(sx.newMemberIcon)}>New</Tag>}
            </>
          );
        },
      },
    ];

    if (isPharmacistTeam) {
      headers.push({
        title: 'DOB',
        dataIndex: 'DOB',
        key: 'DOB',
        width: '8%',
        ...filterSearchBox('birthday', 'MM-DD-YYYY', tableActions?.filters?.DOB),
        filteredValue: tableActions?.filters?.DOB,
        render: (text, record) => {
          const isNewMember = record.action_items.includes('New members');
          const isAlreadyClicked = record.click_redirect;
          return isNewMember && !isAlreadyClicked && isPharmacistTeam ? <b>{text}</b> : <>{text}</>;
        },
      });
    }

    headers.push({
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '8%',
      filters: getStatusFiltersForMembers(isPharmacistTeam),
      filterMultiple: false,
      filteredValue: tableActions?.filters?.status || null,
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder: tableActions?.sorter?.columnKey === 'status' && tableActions?.sorter?.order,
      render: (text, record) => {
        const isNewMember = record.action_items.includes('New members');
        const isAlreadyClicked = record.click_redirect;
        return (
          <Text
            className={isNewMember && !isAlreadyClicked && isPharmacistTeam ? css(sx.boldText) : ''}
          >
            {statusTypes[text] || '-'}
          </Text>
        );
      },
    });

    if (isSystemAdminOrAsc) {
      const ascOrSysAdminColumns = [
        {
          title: 'Pharmacy',
          dataIndex: 'pharmacy',
          key: 'pharmacy',
          width: '12%',
          ...filterSearchBox('pharmacy', 'Pharmacy', tableActions?.filters?.pharmacy),
          filteredValue: tableActions?.filters?.pharmacy || null,
          sorter: true,
          sortDirections: ['ascend', 'descend', 'ascend'],
          sortOrder: tableActions?.sorter?.columnKey === 'pharmacy' && tableActions?.sorter?.order,
          render: (text, record) => {
            const isNewMember = record.action_items.includes('New members');
            const isAlreadyClicked = record.click_redirect;
            return (
              <Text
                className={
                  isNewMember && !isAlreadyClicked && isPharmacistTeam ? css(sx.boldText) : ''
                }
              >
                {text || '--'}
              </Text>
            );
          },
        },
        {
          title: 'Language',
          dataIndex: 'language',
          key: 'language',
          width: '8%',
          filters: languages,
          filteredValue: tableActions?.filters?.language || null,
          sorter: true,
          sortDirections: ['ascend', 'descend', 'ascend'],
          sortOrder: tableActions?.sorter?.columnKey === 'language' && tableActions?.sorter?.order,
          render: (text, record) => {
            const isNewMember = record.action_items.includes('New members');
            const isAlreadyClicked = record.click_redirect;
            return (
              <Text
                className={
                  isNewMember && !isAlreadyClicked && isPharmacistTeam ? css(sx.boldText) : ''
                }
              >{`${text?.[0]?.toUpperCase() + text?.slice(1) || '--'}`}</Text>
            );
          },
        },
        {
          title: 'Plan Name',
          dataIndex: 'plan_name',
          key: 'plan_name',
          width: '12%',
          ...filterSearchBox('plan_name', 'Plan Name', tableActions?.filters?.plan_name),
          filteredValue: tableActions?.filters?.plan_name || null,
          render: (text, record) => {
            const isNewMember = record.action_items.includes('New members');
            const isAlreadyClicked = record.click_redirect;
            return (
              <Text
                className={
                  isNewMember && !isAlreadyClicked && isPharmacistTeam ? css(sx.boldText) : ''
                }
              >
                {text}
              </Text>
            );
          },
        },
      ];

      ascOrSysAdminColumns.forEach(col => headers.push(col));
    }

    headers.push({
      title: 'Outreach Type',
      key: 'outreach_type',
      dataIndex: 'outreach_type',
      width: '8%',
      filters: Object.values(outReachType),
      filteredValue: tableActions?.filters?.outreach_type || null,
      render: (text, record) => {
        const isNewMember = record.action_items.includes('New members');
        const isAlreadyClicked = record.click_redirect;
        return isNewMember && !isAlreadyClicked && isPharmacistTeam ? <b>{text}</b> : <>{text}</>;
      },
    });

    headers.push({
      title: isPharmacistTeam ? 'Action Items' : 'Alerts',
      key: 'action_items',
      dataIndex: 'action_items',
      // filters: Object.values(ActionItems),
      // filteredValue: tableActions?.filters?.action_items || null,
      width: '18%',
      render: (text, record) => {
        const isNewMember = record.action_items.includes('New members');
        const isAlreadyClicked = record.click_redirect;
        return isNewMember && !isAlreadyClicked && isPharmacistTeam ? (
          <b>{text.map(text => text).join(', ')}</b>
        ) : (
          <>{text.map(text => text).join(', ')}</>
        );
      },
    });

    return headers;
  };

  const fetchMemberList = (payload = cloneDeep(tableActions), refetch = false) => {
    if (payload?.sorter && !payload?.sorter?.order) {
      payload.sorter = null;
    }

    const variables = generateVariables(payload);
    if (isPharmacistTeam) {
      variables.query.pharmacy_id = pharmacyId;
    }

    if (refetch) {
      refetchMemberList({
        ...variables,
      });
    } else {
      getMembersList({
        variables: {
          ...variables,
        },
      });
    }
  };

  // will be changed to redirect it to the API.
  useEffect(() => {
    fetchMemberList();
  }, []);

  const onTableChange = (pagination, filters, sorter, { action }) => {
    dispatch({
      type: ActionTypes.UPDATE_MEMBERLIST_TABLE_SETTINGS,
      payload: {
        pagination: action === 'paginate' ? pagination : { ...pagination, current: 1 },
        filters,
        sorter,
      },
    });

    // To stay consistent always re-direct user to page 1 on filter/sort.
    if (action === 'paginate') {
      fetchMemberList({
        pagination,
        filters,
        sorter,
      });
    } else {
      fetchMemberList({
        filters,
        sorter,
      });
    }
  };

  const clearAllFilters = () => {
    const payload = {
      pagination: defaultTableActions.pagination,
      filters: null,
      sorter: null,
    };

    dispatch({
      type: ActionTypes.UPDATE_MEMBERLIST_TABLE_SETTINGS,
      payload,
    });

    fetchMemberList(payload);
  };

  const getTableOptions = () => {
    const tableOptions = {
      columns: generateColumns(),
      dataSource: data,
      size: 'middle',
      rowKey: record => record.id,
      onChange: onTableChange,
      loading: isFetchMembersList,
      pagination: {
        defaultPageSize: 25,
        showSizeChanger: true,
        pageSizeOptions: [25, 50, 100],
        total: membersData?.getEnrolledMembersForPharmacy?.total || 0,
        current: tableActions?.pagination?.current || 1,
        pageSize: tableActions?.pagination?.pageSize,
      },
    };

    return tableOptions;
  };

  // need to create provider for managing the filters.
  return (
    <>
      <div className={css(sx.membersTableOptionContainer)}>
        <Button
          type="secondary"
          onClick={clearAllFilters}
          className={css(sx.membersTableOptionsButtonMargin)}
        >
          Clear All Filters
        </Button>
      </div>
      <Space className={css(sx.membersTextContainer)}>
        <Text className={css(sx.membersText)}>Total Members: {totalMembers}</Text>
      </Space>
      <Table {...getTableOptions()} />
    </>
  );
};

export default withSelectPatient(withRouter(withRoles(MembersTable)));
